<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ viewStats ? $t("stats") : $t("configs") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="viewStats = !viewStats" v-on="on">
            <v-icon>
              {{ viewStats ? "mdi-tune" : "mdi-flash" }}
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ viewStats ? $t("show configs") : $t("show stats") }}
        </span>
      </v-tooltip>
      <wiki slug="power-stats" />
    </v-toolbar>
    <v-card-text>
      <rzo-power-notify-configs
        v-if="!viewStats"
        :product-id="productId"
        :cages="[]"
        class="mb-4"
      />
      <rzo-power-report-configs v-if="!viewStats" :cages="[]" class="mb-4" />
      <v-data-table
        v-if="viewStats"
        :headers="headers"
        :items="racks"
        item-key="rack"
        :expanded.sync="expanded"
        :loading="loading"
        :mobile-breakpoint="mbreakpoint"
        :search="search"
        sort-by="rack"
        :hide-default-footer="racks.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        @click:row="expandRow"
        class="row-pointer"
        show-expand
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="9">
              <v-text-field
                v-model="search"
                append-outer-icon="mdi-magnify"
                :label="$t('search')"
                single-line
                :clearable="!isMobile"
              />
            </v-col>
            <v-col cols="3">
              <v-switch v-model="optional" :label="$t('include all phases')" />
            </v-col>
          </v-row>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <rzo-power-stats-details :rack="item.rack" />
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import RzoPowerStatsDetails from "@/components/services/rzo/RzoPowerStatsDetails";
import RzoPowerNotifyConfigs from "@/components/services/rzo/RzoPowerNotifyConfigs";
import RzoPowerReportConfigs from "@/components/services/rzo/RzoPowerReportConfigs";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "RzoPowerData",
  components: {
    RzoPowerNotifyConfigs,
    RzoPowerReportConfigs,
    RzoPowerStatsDetails,
    Wiki,
  },
  props: {
    productId: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  mixins: [isMobile],
  data: () => ({
    viewStats: true,
    expanded: [],
    racks: [],
    loading: false,
    search: "",
    optional: false,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("rack"),
          value: "rack",
          filterable: true,
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  watch: {
    "$store.state.session": function () {
      this.racks = [];
      this.getRacks();
    },
    optional() {
      this.getRacks();
    },
  },
  methods: {
    expandRow(rack, row) {
      // expand if not expanded otherwise unexpand
      if (
        this.expanded.filter(function (r) {
          return r.rack == rack.rack;
        }).length == 0
      )
        this.expanded.push(rack);
      else
        this.expanded = this.expanded.filter(function (r) {
          return r.rack != rack.rack;
        });
    },
    getRacks: function () {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/rzo/power/racks", {
          params: {
            product_id: this.productId,
            optional: this.optional ? null : false,
          },
        })
        .then((response) => {
          that.racks = response.data;
        })
        .catch((error) => {
          if (error.status === 400)
            console.log("ignore user error: ", error.message);
          else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.getRacks();
  },
};
</script>

<i18n>
{
  "en": {
    "stats": "Stats",
    "configs": "Configs",
    "show configs": "show configs",
    "show stats": "show stats",
    "search": "Search",
    "rack": "Rack",
    "include all phases": "Include all phases"
  }, 
  "de": {
    "stats": "Statistiken",
    "configs": "Konfigurationen",
    "show configs": "zeige Konfigurationen",
    "show stats": "zeige Statistiken",
    "search": "Suche",
    "rack": "Rack",
    "include all phases": "Alle Phasen anzeigen"
  }
}
</i18n>

<style>
.row-pointer
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td.text-start:hover {
  cursor: pointer;
}
</style>
