<template>
  <v-card class="mt-4 mb-4" outlined>
    <validation-observer ref="obs" v-slot="{ errors, invalid }">
      <v-card-text>
        <v-row align="baseline" justify="center">
          <v-col cols="3">
            <validation-provider
              vid="rack"
              :name="$t('rack')"
              rules="max:10"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-select
                v-model="localItem.rack"
                :items="racksWithNone"
                item-text="text"
                item-value="value"
                :loading="loadingRacks"
                name="rack"
                :label="$t('rack')"
                :clearable="!isMobile"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                chips
              />
            </validation-provider>
          </v-col>
          <v-col cols="3">
            <validation-provider
              vid="frequency"
              :name="$t('frequency')"
              rules="required"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-select
                v-model="localItem.frequency"
                :items="frequencies"
                name="frequency"
                :label="$t('frequency')"
                :clearable="!isMobile"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                chips
              >
                <template v-slot:item="{ item }"> {{ $t(item) }} </template>
                <template v-slot:selection="{ item }">
                  {{ $t(item) }}
                </template>
              </v-select>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <email-list-input
              v-model="localItem.email"
              name="email"
              :label="$t('email')"
              max-length="1024"
              multiple
              required
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="item && item.id"
          :loading="loading"
          :disabled="loading"
          @click="deleteItem"
          text
        >
          {{ $t("delete") }}
        </v-btn>
        <v-btn
          v-if="!(item && item.id)"
          :loading="loading"
          :disabled="loading"
          @click="$emit('canceled')"
          text
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="invalid || loading || isUnChanged"
          @click="item && item.id ? saveItem() : createItem()"
          text
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import EmailListInput from "@/components/basics/EmailListInput";
import PhoneListInput from "@/components/basics/PhoneListInput";
import formatPhoneNumber from "@/utils/mixins/formatPhoneNumber";
import isMobile from "@/utils/mixins/isMobile";

var emptyItem = {
  id: null,
  rack: null,
  frequency: "daily",
  email: null,
};

export default {
  name: "RzoPowerReportConfigLimits",
  components: {
    EmailListInput,
    PhoneListInput,
  },
  mixins: [formatPhoneNumber, showErrors, isMobile],
  props: {
    item: {
      type: Object,
      validator: (prop) => typeof prop === "object" || prop === null,
      default: null,
    },
    racks: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    racks: [],
    loadingRacks: false,
    loading: false,
    deleting: false,
    localItem: emptyItem,
    frequencies: ["daily", "weekly", "monthly"],
  }),
  computed: {
    isUnChanged() {
      return (
        this.item != null &&
        this.localItem.rack == this.item.rack &&
        this.localItem.email == this.item.email &&
        this.localItem.frequency == this.item.frequency
      );
    },
    racksWithNone() {
      return [
        {
          text: this.$i18n.t("all"),
          value: null,
        },
        ...this.racks.map(function (item) {
          return {
            text: item.rack,
            value: item.rack,
          };
        }),
      ];
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(value) {
        this.getRacks();
        if (value == null) this.localItem = emptyItem;
        else this.localItem = { ...value };
      },
    },
  },
  methods: {
    getRacks: function () {
      var that = this;
      this.loadingRacks = true;
      this.$http
        .get("services/rzo/power/racks", {
          params: { product_id: this.productId },
        })
        .then((response) => {
          that.racks = response.data;
        })
        .catch((error) => {
          if (error.status === 400)
            console.log("ignore user error: ", error.message);
          else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loadingRacks = false;
        });
    },
    saveItem() {
      var that = this;
      this.loading = true;
      this.$http
        .patch("services/rzo/power/report-configs/" + this.localItem.id, {
          rack: this.localItem.rack,
          frequency: this.localItem.frequency,
          email: this.localItem.email,
        })
        .then((response) => {
          that.$emit("update:item", response.data);
          that.$emit("updated");
        })
        .catch((error) => {
          if (error.invalid()) {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    createItem() {
      var that = this;
      this.loading = true;
      this.$http
        .post("services/rzo/power/report-configs", {
          rack: this.localItem.rack,
          frequency: this.localItem.frequency,
          email: this.localItem.email,
        })
        .then((response) => {
          that.$emit("created");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    deleteItem() {
      var that = this;
      this.deleting = true;
      this.$http
        .delete("services/rzo/power/report-configs/" + this.localItem.id)
        .then((response) => {
          that.$emit("deleted");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.deleting = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "rack": "Rack",
    "notify email": "Send email",
    "email": "Email",
    "frequency": "Frequency",
    "all": "All",
    "save": "Save",
    "delete": "Delete",
    "daily": "daily",
    "weekly": "weekly",
    "monthly": "monthly"
  },
  "de": {    
    "rack": "Rack",
    "notify email": "Sende Email",
    "email": "Email",
    "frequency": "Wiederholung",
    "all": "Alle",
    "save": "Speichern",
    "delete": "Löschen",
    "daily": "täglich",
    "weekly": "wöchentlich",
    "monthly": "monatlich"
  }
}
</i18n>
