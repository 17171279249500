<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("notify") }}
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              dialogLimits = !dialogLimits;
              dialog = !dialogLimits;
            "
            :disabled="loading || dialogLimits"
            icon
          >
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("overwrite limits") }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              dialog = !dialog;
              dialogLimits = !dialog;
            "
            :disabled="loading || dialog"
            icon
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("add entry") }}</span>
      </v-tooltip>
      <wiki slug="power-notify" />
    </v-toolbar>
    <v-card-text>
      <data-table-extended
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        :expanded="expanded"
        :mobile-breakpoint="mbreakpoint"
        sort-by="id"
        sort-desc
        :hide-default-footer="items.length <= 15"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [15, 50],
        }"
        show-expand
        single-expand
        @item-expanded="
          dialog = false;
          dialogLimits = false;
        "
        show-menu
        local-storage-name="rzoPowerNotifyConfigList"
        open-expand-by-dbl-click-row
      >
        <template v-slot:top>
          <rzo-power-notify-config-details
            v-if="dialog"
            @created="
              expanded = [];
              dialog = false;
              dialogLimits = false;
              getItems();
            "
            @canceled="
              expanded = [];
              dialog = false;
              dialogLimits = false;
            "
          />
          <rzo-power-notify-config-limits
            v-else-if="dialogLimits"
            @takeOver="
              expanded = [];
              dialog = false;
              dialogLimits = false;
              setLimits($event);
            "
            @canceled="
              expanded = [];
              dialog = false;
              dialogLimits = false;
            "
          />
          <v-text-field
            v-else
            class="mb-4"
            v-model="search"
            append-outer-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
            clearable
            v-bind:label="$t('search')"
            single-line
            hide-details
          />
        </template>

        <template v-slot:item.max_critical_limit="{ item }">
          <div class="monospace text-no-wrap">
            {{
              item.max_critical_limit != null
                ? formatNumber(item.max_critical_limit, "A")
                : "-"
            }}
          </div>
        </template>

        <template v-slot:item.max_warning_limit="{ item }">
          <div class="monospace text-no-wrap">
            {{
              item.max_warning_limit != null
                ? formatNumber(item.max_warning_limit, "A")
                : "-"
            }}
          </div>
        </template>

        <template v-slot:item.min_warning_limit="{ item }">
          <div class="monospace text-no-wrap">
            {{
              item.min_warning_limit != null
                ? formatNumber(item.min_warning_limit, "A")
                : "-"
            }}
          </div>
        </template>

        <template v-slot:item.email="{ item }">
          <email-list-input v-model="item.email" disabled />
        </template>

        <template v-slot:item.notify_email="{ item }">
          <v-simple-checkbox v-model="item.notify_email" disabled />
        </template>

        <template v-slot:item.notify_mobile="{ item }">
          <v-simple-checkbox v-model="item.notify_mobile" disabled />
        </template>

        <template v-slot:item.mobile="{ item }">
          <phone-list-input v-model="item.mobile" disabled />
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <rzo-power-notify-config-details
              :item="item"
              @deleted="
                expanded = [];
                getItems();
              "
              @updated="
                expanded = [];
                getItems();
              "
            />
          </td>
        </template>
      </data-table-extended>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";

import BooleanValue from "@/components/basics/BooleanValue";
import EmailListInput from "@/components/basics/EmailListInput";
import PhoneListInput from "@/components/basics/PhoneListInput";
import DataTableExtended from "@/components/basics/DataTableExtended";
import formatNumber from "@/utils/mixins/formatNumber";
import isMobile from "@/utils/mixins/isMobile";
import RzoPowerNotifyConfigDetails from "@/components/services/rzo/RzoPowerNotifyConfigDetails";
import RzoPowerNotifyConfigLimits from "@/components/services/rzo/RzoPowerNotifyConfigLimits";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "RzoPowerNotifyConfigs",
  components: {
    BooleanValue,
    EmailListInput,
    PhoneListInput,
    DataTableExtended,
    RzoPowerNotifyConfigDetails,
    RzoPowerNotifyConfigLimits,
    Wiki,
  },
  mixins: [formatNumber, isMobile],
  data: () => ({
    dialog: false,
    dialogLimits: false,
    expanded: [],
    items: [],
    search: "",
    loading: false,
    loadingSetLimits: false,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$i18n.t("rack"),
          value: "rack",
        },
        {
          text: this.$i18n.t("max warning limit"),
          value: "max_warning_limit",
        },
        {
          text: this.$i18n.t("max critical limit"),
          value: "max_critical_limit",
        },
        {
          text: this.$i18n.t("min warning limit"),
          value: "min_warning_limit",
        },
        {
          text: this.$i18n.t("email"),
          value: "email",
          hidden: true,
        },
        {
          text: this.$i18n.t("notify email"),
          value: "notify_email",
        },
        {
          text: this.$i18n.t("mobile"),
          value: "mobile",
          hidden: true,
        },
        {
          text: this.$i18n.t("notify mobile"),
          value: "notify_mobile",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  watch: {
    "$store.state.session": function () {
      this.items = [];
      this.getItems();
    },
    dialog() {
      if (this.dialog) this.expanded = [];
    },
    dialogLimits() {
      if (this.dialogLimits) this.expanded = [];
    },
  },
  methods: {
    getItems() {
      var that = this;
      that.loading = true;
      this.$http
        .get("services/rzo/power/notify-configs")
        .then((response) => {
          that.items = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    setLimits(event) {
      var that = this;
      var loadingSetLimits = true;
      var requests = this.items.map(function (item) {
        return that.$http.patch(
          "services/rzo/power/notify-configs/" + item.id,
          {
            rack: item.rack,
            email: item.email,
            notify_email: item.notify_email,
            mobile: item.mobile,
            notify_mobile: item.notify_mobile,
            max_warning_limit: event.max_warning_limit || null,
            max_critical_limit: event.max_critical_limit || null,
            min_warning_limit: event.min_warning_limit || null,
          }
        );
      });
      axios
        .all(requests)
        .then(
          axios.spread((...responses) => {
            that.getItems();
          })
        )
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingSetLimits = false;
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<i18n>
{
  "en": {
    "notify": "Notify",
    "rack": "Rack",
    "email": "Email",
    "notify email": "Send email",
    "mobile": "Mobile",
    "notify mobile": "Send SMS",
    "add entry": "add entry",
    "max critical limit": "max. Critical Limit",
    "max warning limit": "max. Warning Limit",
    "min critical limit": "min. Critical Limit",
    "overwrite limits": "overwrite alarm limits"
  }, 
  "de": {
    "notify": "Benachrichtigung",
    "rack": "Rack",
    "email": "Email",
    "notify email": "Sende Email",
    "mobile": "Mobil",
    "notify mobile": "Sende SMS",
    "add entry": "Eintrag hinzufügen",
    "max critical limit": "max. kritische Grenze",
    "max warning limit": "max. Grenze Warnungen",
    "min critical limit": "min. kritische Grenze",
    "overwrite limits": "überschreibe Alarmgrenzen"
  }
}
</i18n>
