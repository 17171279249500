<template>
  <v-card class="mt-4 mb-4" outlined>
    <validation-observer ref="obs" v-slot="{ errors, invalid }">
      <v-card-text>
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12">
            <validation-provider
              vid="rack"
              :name="$t('rack')"
              rules="max:10"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-select
                v-model="localItem.rack"
                :items="racksWithNone"
                item-text="text"
                item-value="value"
                :loading="loadingRacks"
                name="rack"
                :label="$t('rack')"
                :clearable="!isMobile"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                chips
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row align="baseline" justify="center" no-gutters>
          <v-col cols="4">
            <validation-provider
              vid="notify_email"
              :name="$t('notify email')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-switch
                v-model="localItem.notify_email"
                name="notify_email"
                :label="$t('notify email')"
                :error-messages="errors"
                :class="classes"
              />
            </validation-provider>
          </v-col>
          <v-col cols="8">
            <email-list-input
              v-model="localItem.email"
              name="email"
              :label="$t('email')"
              max-length="1024"
              multiple
              :required="localItem.notify_email"
            />
          </v-col>
        </v-row>
        <v-row align="baseline" justify="center" no-gutters>
          <v-col cols="4">
            <validation-provider
              vid="notify_mobile"
              :name="$t('notify mobile')"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-switch
                v-model="localItem.notify_mobile"
                name="notify_mobile"
                :label="$t('notify mobile')"
                :error-messages="errors"
                :class="classes"
              />
            </validation-provider>
          </v-col>
          <v-col cols="8">
            <validation-provider
              vid="mobile"
              :name="$t('mobile')"
              rules="phone"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <phone-list-input
                v-model="localItem.mobile"
                :multiple="true"
                name="mobile"
                :label="$t('mobile')"
                max-length="100"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                :required="localItem.notify_mobile"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row align="baseline" justify="center" no-gutters>
          <v-col cols="4">
            <validation-provider
              vid="max_warning_limit"
              :name="$t('max warning limit')"
              rules="double|min:0"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="localItem.max_warning_limit"
                name="max_warning_limit"
                :label="$t('max warning limit')"
                :clearable="!isMobile"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                type="number"
                step="0.1"
              />
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              vid="max_critical_limit"
              :name="$t('max critical limit')"
              rules="required|double|min:0"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="localItem.max_critical_limit"
                name="max_critical_limit"
                :label="$t('max critical limit')"
                :clearable="!isMobile"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                type="number"
                step="0.1"
              />
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              vid="min_warning_limit"
              :name="$t('min warning limit')"
              rules="double|min:0"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="localItem.min_warning_limit"
                name="min_warning_limit"
                :label="$t('min warning limit')"
                :clearable="!isMobile"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                type="number"
                step="0.1"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="localItem.id"
          :loading="deleting"
          :disabled="loading || deleting"
          @click="deleteItem"
          text
        >
          {{ $t("delete") }}
        </v-btn>
        <v-btn
          v-if="!localItem.id"
          :disabled="loading || deleting"
          @click="$emit('canceled')"
          text
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="invalid || loading || deleting"
          @click="localItem.id == null ? createItem() : saveItem()"
          text
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import EmailListInput from "@/components/basics/EmailListInput";
import PhoneListInput from "@/components/basics/PhoneListInput";
import formatPhoneNumber from "@/utils/mixins/formatPhoneNumber";
import isMobile from "@/utils/mixins/isMobile";

var emptyItem = {
  id: null,
  rack: null,
  email: null,
  mobile: null,
  notify_email: false,
  notify_mobile: false,
  default: false,
  max_critical_limit: 7.5,
  max_warning_limit: 7.0,
  min_warning_limit: null,
};

export default {
  name: "RzoPowerNotifyConfigDetails",
  components: {
    EmailListInput,
    PhoneListInput,
  },
  mixins: [formatPhoneNumber, showErrors, isMobile],
  props: {
    item: {
      type: Object,
      validator: (prop) => typeof prop === "object" || prop === null,
      default: null,
    },
  },
  data: () => ({
    racks: [],
    loadingRacks: false,
    loading: false,
    deleting: false,
    localItem: emptyItem,
  }),
  computed: {
    racksWithNone() {
      return [
        {
          text: this.$i18n.t("all"),
          value: null,
        },
        ...this.racks.map(function (item) {
          return {
            text: item.rack,
            value: item.rack,
          };
        }),
      ];
    },
    isCageSelected() {
      return (
        this.localItem &&
        this.localItem.rack &&
        this.localItem.rack.toLowerCase().indexOf("cage") != -1
      );
    },
  },
  watch: {
    item: {
      immediate: true,
      handler(value) {
        this.getRacks();
        if (value == null) this.localItem = emptyItem;
        else this.localItem = { ...value };
      },
    },
    "localItem.rack": function (value) {
      if (this.isCageSelected) {
        // TODO: define defaults!!!
        this.localItem.max_critical_limit = null;
        this.localItem.max_warning_limit = null;
      }
    },
  },
  methods: {
    getRacks: function () {
      var that = this;
      this.loadingRacks = true;
      this.$http
        .get("services/rzo/power/racks", {
          params: { product_id: this.productId },
        })
        .then((response) => {
          that.racks = response.data;
        })
        .catch((error) => {
          if (error.status === 400)
            console.log("ignore user error: ", error.message);
          else
            that.$store.commit("setSystemError", {
              msg: error.message,
              title: error.title,
            });
        })
        .finally(function () {
          that.loadingRacks = false;
        });
    },
    saveItem() {
      var that = this;
      this.loading = true;
      this.$http
        .patch("services/rzo/power/notify-configs/" + this.localItem.id, {
          rack: this.localItem.rack,
          email: this.localItem.email,
          notify_email: this.localItem.notify_email,
          mobile: this.localItem.mobile,
          notify_mobile: this.localItem.notify_mobile,
          max_warning_limit: this.localItem.max_warning_limit,
          max_critical_limit: this.localItem.max_critical_limit,
          min_warning_limit: this.localItem.min_warning_limit,
        })
        .then((response) => {
          that.$emit("update:item", response.data);
          that.$emit("updated");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    createItem() {
      var that = this;
      this.loading = true;
      this.$http
        .post("services/rzo/power/notify-configs", {
          rack: this.localItem.rack,
          email: this.localItem.email,
          notify_email: this.localItem.notify_email,
          mobile: this.localItem.mobile,
          notify_mobile: this.localItem.notify_mobile,
          max_warning_limit: this.localItem.max_warning_limit,
          max_critical_limit: this.localItem.max_critical_limit,
          min_warning_limit: this.localItem.min_warning_limit,
        })
        .then((response) => {
          that.$emit("created");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    deleteItem() {
      var that = this;
      this.deleting = true;
      this.$http
        .delete("services/rzo/power/notify-configs/" + this.localItem.id)
        .then((response) => {
          that.$emit("deleted");
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.deleting = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "rack": "Rack",
    "notify email": "Send email",
    "email": "Email",
    "notify mobile": "Send SMS",
    "mobile": "Mobile",
    "all": "All",
    "save": "Save",
    "delete": "Delete",
    "max warning limit": "max. warning limit (A)",
    "max critical limit": "max. critical limit (A)",
    "min warning limit": "min. warning limit (A)"
  },
  "de": {    
    "rack": "Rack",
    "notify email": "Sende Email",
    "email": "Email",
    "notify mobile": "Sende SMS",
    "mobile": "Mobil Nummer",
    "all": "Alle",
    "save": "Speichern",
    "delete": "Löschen",
    "max warning limit": "max. Grenze für Warnung (A)",
    "max critical limit": "max. kritische Grenze (A)",
    "min warning limit": "min. Grenze für Warnung (A)"
  }
}
</i18n>
