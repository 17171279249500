<template>
  <v-card class="mt-4 mb-4" outlined>
    <validation-observer ref="obs" v-slot="{ errors, invalid }">
      <v-card-text>
        <span v-html="$t('infoText')" class="ma-4" />
      </v-card-text>
      <v-card-text>
        <v-row align="baseline" justify="center" no-gutters>
          <v-col cols="4">
            <validation-provider
              vid="max_warning_limit"
              :name="$t('max warning limit')"
              rules="double|min:0"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="max_warning_limit"
                name="max_warning_limit"
                :label="$t('max warning limit')"
                :clearable="!isMobile"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                type="number"
                step="0.1"
              />
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              vid="max_critical_limit"
              :name="$t('max critical limit')"
              rules="required|double|min:0"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="max_critical_limit"
                name="max_critical_limit"
                :label="$t('max critical limit')"
                :clearable="!isMobile"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                type="number"
                step="0.1"
              />
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              vid="min_warning_limit"
              :name="$t('min warning limit')"
              rules="double|min:0"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="min_warning_limit"
                name="min_warning_limit"
                :label="$t('min warning limit')"
                :clearable="!isMobile"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                type="number"
                step="0.1"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="$emit('canceled')" text>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="invalid"
          @click="
            $emit('takeOver', {
              max_critical_limit: max_critical_limit,
              max_warning_limit: max_warning_limit,
              min_warning_limit: min_warning_limit,
            })
          "
          text
        >
          {{ $t("apply") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "RzoPowerNotifyConfigLimits",
  mixins: [isMobile],
  data: () => ({
    max_critical_limit: null,
    max_warning_limit: null,
    min_warning_limit: null,
  }),
};
</script>

<i18n>
{
  "en": {
    "infoText": "Apply thresholds for all notification entries!",
    "apply": "apply",
    "cancel": "Cancel",
    "max warning limit": "max. warning limit (A)",
    "max critical limit": "max. critical limit (A)",
    "min warning limit": "min. warning limit (A)"
  },
  "de": {    
    "infoText": "Übernehme Genzwerte für alle Benachrichtigungseinträge!",
    "apply": "Übernehmen",
    "cancel": "Abbrechen",
    "max warning limit": "max. Grenze für Warnung (A)",
    "max critical limit": "max. kritische Grenze (A)",
    "min warning limit": "min. Grenze für Warnung (A)"
  }
}
</i18n>
