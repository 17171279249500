<template>
  <v-card class="mt-4 mb-4" outlined>
    <v-progress-linear :active="loading" indeterminate />
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>{{ rack }}</v-toolbar-title>
      <v-spacer />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            :loading="loadingPdf"
            :disabled="loadingPdf"
            @click="downloadPdf"
            icon
          >
            <v-icon>$PdfDownload</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("download stats as PDF") }}</span>
      </v-tooltip>
      <date-picker
        v-model="dates"
        :allowedDates="dateAllowed"
        icon
        range
        sort-range
        left
        bottom
        rounded
      />
      <v-menu left bottom rounded>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-vector-arrange-above</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item-group v-model="interval" color="primary">
            <v-list-item
              v-for="(choice, i) in intervalChoices"
              :key="i"
              :disabled="i == interval"
            >
              <v-list-item-title>
                {{ $t(choice.text) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-toolbar>
    <div v-for="(serie, name, index) in series" :key="index">
      <v-card-title>{{ $t(name) }}</v-card-title>
      <v-card-text>
        <v-simple-table v-if="name == 'energy'">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>{{ formatDate(dates[0]) }} - {{ formatDate(dates[1]) }}</td>
                <td>
                  <v-chip @click="showEnergyGraph = !showEnergyGraph">
                    {{ formatNumber(summaryEnergy, summaryEnergyUnit) }}
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <apexchart
          v-if="name != 'energy' || showEnergyGraph"
          :options="serie.options"
          :series="serie.series"
          height="360px"
          class="flex-grow-1"
        />
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import downloadFile from "@/utils/mixins/downloadFile";
import DatePicker from "@/components/basics/DatePicker";
import formatNumber from "@/utils/mixins/formatNumber";
import formatDate from "@/utils/mixins/formatDate";
import formatDateTime from "@/utils/mixins/formatDateTime";
import VueApexCharts from "vue-apexcharts";

var moment = require("moment");

export default {
  name: "RzoPowerStatsDetails",
  components: {
    DatePicker,
    apexchart: VueApexCharts,
  },
  props: {
    rack: {
      type: String,
      required: true,
    },
  },
  mixins: [downloadFile, formatNumber, formatDate, formatDateTime],
  data: () => ({
    showEnergyGraph: false,
    interval: 1,
    intervalChoices: [
      { seconds: 300, text: "5minutes" },
      { seconds: 3600, text: "hourly" },
      { seconds: 86400, text: "daily" },
    ],
    series: {},
    summaryEnergy: 0.0,
    summaryEnergyUnit: null,
    loading: false,
    loadingPdf: false,
    dates: [
      moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD"),
    ],
    baseOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        stacked: false,
        type: "line",
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        onItemClick: {
          toggleDataSeries: true,
        },
      },
      xaxis: {
        type: "datetime",
        labels: {
          datetimeUTC: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
    },
  }),
  watch: {
    "$vuetify.theme.dark": {
      immediate: true,
      handler(value) {
        this.baseOptions = {
          ...this.baseOptions,
          tooltip: {
            theme: value ? "dark" : "light",
          },
          noData: {
            text: this.$i18n.t("no data"),
          },
        };
      },
    },
    rack() {
      this.getData();
    },
    dates(value) {
      if (value && value.length == 2) {
        this.getData();
      }
    },
    interval(value) {
      this.getData();
    },
  },
  methods: {
    dateAllowed(date) {
      const today = this.$moment(new Date()).format("YYYY-MM-DD");
      return (
        this.$moment(today).isSame(date, "day") ||
        this.$moment(date).isBefore(today)
      );
    },
    getData() {
      if (!this.loading) {
        var that = this;
        this.loading = true;
        this.$http
          .get("services/rzo/power/data", {
            params: {
              rack: this.rack,
              interval: this.intervalChoices[this.interval].seconds,
              time_after:
                this.dates.length > 0 ? this.dates[0] + " 00:00:00" : null,
              time_before:
                this.dates.length > 1 ? this.dates[1] + " 23:59:59" : null,
            },
          })
          .then((response) => {
            that.summaryEnergy = 0;
            that.series = {};
            response.data.forEach(function (serie) {
              var name = "";
              if (serie.rack_additional) name = serie.rack_additional + " - ";
              if (serie.product_id == null) name = (serie.rack || "*") + " - ";
              name =
                name +
                " " +
                that.$t(serie.rv) +
                " " +
                " (" +
                serie.serie.toUpperCase() +
                ")";

              // initialize serie
              if (!that.series.hasOwnProperty(serie.type)) {
                that.series[serie.type] = {
                  options: {
                    ...that.baseOptions,
                    yaxis: {
                      forceNiceScale: true,
                      title: {
                        text: serie.unit,
                      },
                    },
                  },
                  series: [],
                };
              }

              if (
                serie.type == "energy" &&
                serie.rv == "ABS" &&
                serie.data.length
              ) {
                that.summaryEnergy +=
                  serie.data[serie.data.length - 1][1] - serie.data[0][1];
                that.summaryEnergyUnit = serie.unit;
              }

              // append serie
              that.series[serie.type].series.push({
                name: name,
                data: serie.data.map(function (e) {
                  return {
                    x: e[0],
                    y: (e[1] || 0).toFixed(3),
                  };
                }),
              });
            });
          })
          .catch((err) => {
            that.$store.commit("setSystemError", {
              msg: err.message,
              title: err.title,
            });
          })
          .finally(function () {
            that.loading = false;
          });
      }
    },
    downloadPdf() {
      var that = this;
      this.loadingPdf = true;
      this.downloadFile(
        "services/rzo/power/data",
        "get",
        {
          rack: this.rack,
          interval: this.intervalChoices[this.interval].seconds,
          time_after:
            this.dates.length > 0 ? this.dates[0] + " 00:00:00" : null,
          time_before:
            this.dates.length > 1 ? this.dates[1] + " 23:59:59" : null,
        },
        "power-report.pdf",
        "application/pdf",
        function () {
          that.loadingPdf = false;
        },
        function () {},
        true
      );
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<i18n scoped>
{
  "en": {
   "reload": "reload",
   "select interval": "select interval",
   "download stats as PDF": "download stats as PDF",
   "power": "Power",
   "energy": "Energy",
   "amperage": "Amperage",
   "voltage": "Voltage",
   "frequency": "Frequency",
   "temperature": "Temperature",
   "humidity": "Humidity",
   "humidity_relative": "Humidity relative",
   "no data": "no data",
   "MIN": "Minimum",
   "MAX": "Maximum",
   "AVG": "Average",
   "ABS": "Absolute",
   "DIFF": "Difference",
   "5minutes": "5 minutes samples",
   "hourly": "60 minutes aggregates",
   "daily": "24 hours aggregates"
  },
  "de": {
   "reload": "aktualisieren",
   "select interval": "Interval auswählen",
   "download stats as PDF": "Statistik als PDF laden",
   "power": "Leistung",
   "energy": "Energie",
   "amperage": "Stromstärke",
   "voltage": "Spannung",
   "frequency": "Frequenz",
   "temperature": "Temperatur",
   "humidity": "Feuchtigkeit",
   "humidity_relative": "Relative Feuchtigkeit",
   "no data": "no data",
   "MIN": "Minimum",
   "MAX": "Maximum",
   "AVG": "Durchschnitt",
   "ABS": "Absolut",
   "DIFF": "Differenz",
   "5minutes": "5 Minutes Samples",
   "hourly": "60 Minuten Aggregate",
   "daily": "24 Stunden Aggregate"
  }
}
</i18n>
